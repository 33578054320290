/* eslint-disable react/require-default-props */
import React from 'react'
import styles from '../styles/components/HeroTitle.module.css'

interface IHeroTitleP {
  startColor?: string
  midColor?: string
  endColor?: string
  index?: string
  content: string
  animation?: boolean
}

function HeroTitle({
  animation = true,
  startColor,
  midColor,
  endColor,
  index,
  content,
}: IHeroTitleP) {
  const styleVariables = {
    '--start-color': startColor || 'var(--selected-font-color)',
    '--mid-color': midColor || 'var(--selected-font-color)',
    '--end-color': endColor || 'var(--selected-font-color)',
    '--index': index,
  }

  return (
    // @ts-ignore
    <h1 style={styleVariables} className={styles['hero-title-wrapper']}>
      {(animation && (
        <>
          <div className={styles['version-black']}>
            <h1>{content}</h1>
          </div>
          <div className={styles['version-color']}>
            <h1>{content}</h1>
          </div>
        </>
      )) || (
        <div className={styles['version-color']}>
          <h1>{content}</h1>
        </div>
      )}
    </h1>
  )
}

export default HeroTitle
