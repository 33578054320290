/* eslint-disable @typescript-eslint/no-unused-vars */
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { CookieBanner, Footer, NavBar } from '@uniqs-labs/ui-react-lib'
import React from 'react'
import { useRouter } from 'next/router'
import logo from '../assets/images/logo.png'
import logoDark from '../assets/images/logo_dark.png'
import styles from '../styles/components/Layout.module.css'

export default function Layout({ children }: any) {
  const { t } = useTranslation(['layout', 'common', 'app-install'])
  const router = useRouter()

  return (
    <div className={[styles['layout-grid'], 'custom-scroll'].join(' ')}>
      <header className={styles['layout-header']}>
        <NavBar
          logoLight={
            <div className={styles['logo-wrapper']}>
              <Image src={logoDark} alt="logo" />
            </div>
          }
          logoDark={
            <div className={styles['logo-wrapper']}>
              <Image src={logo} alt="logo" />
            </div>
          }
          logoOnClick={() => router.push('/')}
          leftLinks={[]}
          rightLinks={[{ onClick: () => router.push('/contact'), title: t('contact') }]}
          primaryButtonText="Our Products"
          buttonOnClick={() => router.push('/products')}
        />
      </header>
      <main className={styles['layout-main']}>{children}</main>
      <footer className={styles['layout-footer']}>
        <Footer
          hashtag={{ title: t('hashtag'), onClick: () => router.push('/') }}
          links={[
            { title: t('privacy-policy'), onClick: () => router.push('/privacy-policy') },
            { title: t('imprint'), onClick: () => router.push('/imprint') },
            { title: t('cookies-policy'), onClick: () => router.push('/cookies-policy') },
          ]}
        />
        <p>©{new Date().getFullYear()} UNIQS VGMBH</p>
      </footer>
      <CookieBanner
        onClickCookiePolicy={() => router.push('/cookies-policy')}
        onClickPrivacyPolicy={() => router.push('/privacy-policy')}
      />
    </div>
  )
}
