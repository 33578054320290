/* eslint-disable react/require-default-props */
import React from 'react'
import styles from '../styles/components/SectionTitle.module.css'

interface ISectionTitleP {
  startColor: string
  midColor: string
  endColor: string
  index: string
  mainTitle: string
  belowTitle: string
}

function SectionTitle({
  startColor,
  midColor,
  endColor,
  index,
  mainTitle,
  belowTitle,
}: ISectionTitleP) {
  const styleVariables = {
    '--start-color': startColor,
    '--mid-color': midColor,
    '--end-color': endColor,
    '--index': index,
  }

  return (
    // @ts-ignore
    <div style={styleVariables} className={styles['section-title-wrapper']}>
      <div className={styles['styled-line']} />
      <div className={styles['styled-index']}>
        <h1>{index}</h1>
      </div>
      <div className={styles['styled-main-title']}>
        <h1>{mainTitle}</h1>
      </div>
      <h4 className={styles['styled-below-title']}>{belowTitle}</h4>
    </div>
  )
}

export default SectionTitle
