/* eslint-disable @next/next/no-img-element */
import { useTheme, RippleButton } from '@uniqs-labs/ui-react-lib'
// import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Layout from '../components/Layout'
import styles from '../styles/pages/Home.module.css'
import PulsingLogo from '../components/PulsingLogo'
import HeroTitle from '../components/HeroTitle'
import SectionTitle from '../components/SectionTitle'

// @ts-ignore
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['home', 'common', 'layout', 'app-install'])),
      // Make sure to pass also the namespace used in Layout Component
    },
  }
}

export default function About() {
  // const { t } = useTranslation(['home', 'common'])
  const { theme } = useTheme() as any
  const [loading, setLoading] = useState(true)
  const router = useRouter()

  useEffect(() => {
    setLoading(false)
  }, [theme])

  if (loading) return <PulsingLogo />

  return (
    <Layout>
      <div className={styles['page-grid']}>
        <section>
          <HeroTitle animation={false} content="We" />
          <HeroTitle
            startColor="var(--selected-action-color-1)"
            midColor="var(--selected-action-color-2)"
            endColor="var(--selected-action-color-3)"
            index="0"
            content="Build."
          />
          <HeroTitle
            startColor="var(--selected-action-color-3)"
            midColor="var(--selected-action-color-4)"
            endColor="var(--selected-action-color-5)"
            index="1"
            content="Measure."
          />
          <HeroTitle
            startColor="var(--selected-action-color-5)"
            midColor="var(--selected-action-color-6)"
            endColor="var(--selected-action-color-7)"
            index="2"
            content="Learn."
          />
          <HeroTitle
            startColor="var(--selected-action-color-7)"
            midColor="var(--selected-action-color-8)"
            endColor="var(--selected-action-color-9)"
            index="3"
            content="Refine."
          />
          <div className={styles.cta}>
            <RippleButton
              onClick={() => router.push('/products')}
              label="View our products"
              modalOptimized
              primary
              size="small"
            />
            <p>
              At UNIQS we focus on solving everyday problems by building user-centric software
              applications.
            </p>
          </div>
        </section>
        <div className={styles['sub-title']}>
          <h1>Explore how we do it</h1>
        </div>
        <div>
          <section className={styles.section}>
            <SectionTitle
              startColor="var(--selected-action-color-1)"
              midColor="var(--selected-action-color-2)"
              endColor="var(--selected-action-color-3)"
              index="1"
              mainTitle="Build."
              belowTitle="Solving everyday problems."
            />
            <p>
              To build solutions which solve everyday problems, we work in close collaboration with
              lead-users. As they are at the forefront of their profession, they know exactly what
              needs to be changed to execute their profession more effectively.
            </p>
            <p>
              We are responsible to help them express their problems as well as to come up with
              ideas on how to solve them. In collaboration with lead-users we rapid-prototype and
              build the first minimum-viable-product (MVP).
            </p>
          </section>
          <section className={styles.section}>
            <SectionTitle
              startColor="var(--selected-action-color-3)"
              midColor="var(--selected-action-color-4)"
              endColor="var(--selected-action-color-5)"
              index="2"
              mainTitle="Measure."
              belowTitle="Ensuring to deliver perfection."
            />
            <p>
              After successful launch of the product to market, we follow our mission to involve all
              users to shape the product to its perfection.
            </p>
            <p>
              We fulfill this mission by using the latest technologies to anonymously track user
              interactions and measure every aspect of our products.
            </p>
          </section>
          <section className={styles.section}>
            <SectionTitle
              startColor="var(--selected-action-color-5)"
              midColor="var(--selected-action-color-6)"
              endColor="var(--selected-action-color-7)"
              index="3"
              mainTitle="Learn."
              belowTitle="Identifying what delivers value."
            />
            <p>
              Having measured what works and what doesn’t we determine the next action items on how
              to further improve the user experience.
            </p>
            <p>
              By collaborating with early adopters, we further identify the next features to
              implement. We prioritize them based on estimated value creation and time needed of
              delivery.
            </p>
          </section>
          <section className={styles.section}>
            <SectionTitle
              startColor="var(--selected-action-color-7)"
              midColor="var(--selected-action-color-8)"
              endColor="var(--selected-action-color-9)"
              index="4"
              mainTitle="Refine."
              belowTitle="Delivering value continuously."
            />
            <p>
              In the last step of the iterative process, we implement the identified action items to
              improve the user experience. This may mean upgrading our technology stack, reshaping
              the existing product or implementing new features.
            </p>
            <p>
              Regarding the implementation of new features, we make sure that everyone can
              incrementally switch to new features and is not forced to instantly adopt new
              features.
            </p>
          </section>
        </div>
      </div>
    </Layout>
  )
}
